import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export const fetchGeneralStatistic = () =>
    catchAsync(async () => {
        const response = await axios.get('statistic/admin/general');
        return response.data;
    });

export const fetchDashboardStatistic = (regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard', {
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchDashboardCategories = (regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/categories', {
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchDashboardMethods = (regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/methods', {
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchDashboardMethodsByDate = (date, regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/methodsByDate', {
            date,
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchDashboardMethodStats = (date, conditionId, regionName) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/methodStat', { date, conditionId, regionName });

        return response.data;
    });

export const fetchDashboardMethodReport = (conditionId, date, regionName, filters) =>
    //TODO: Проверить корректность формирования filters и нужен ли он вообще
    catchAsync(async () => {
        const response = await axios.post(
            'statistic/admin/dashboard/methodReport',
            { conditionId, date, regionName, filters },
            {
                responseType: 'blob'
            }
        );

        const data = await response.data;
        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.xlsx'); //or any other extension

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);

        return response.data;
    });

export const fetchStatisticByParam = (param, period, regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/byParam', {
            param,
            period,
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchStatisticByCategory = (categoryId, regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/byCategory', {
            categoryId,
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchStatisticByMethod = (methodId, period, regionName, municipalityId, schoolId) =>
    catchAsync(async () => {
        const response = await axios.post('statistic/admin/dashboard/byMethod', {
            methodId,
            period,
            regionName,
            municipalityId,
            schoolId
        });

        return response.data;
    });

export const fetchDashboardExcelReport = (regionName) =>
    catchAsync(async () => {
        const response = await axios.post(
            'reports/dashboard',
            { regionName },
            {
                responseType: 'blob'
            }
        );

        if (response.status === 404) {
            return true;
        }

        const data = await response.data;
        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Отчет_по_региону.xlsx');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);

        return true;
    });
