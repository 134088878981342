import styles from './DashboardStatisticContent.module.scss';
import React from 'react';
import DashboardMainData from './DashboardMainData';
import DashboardGroupsData from './DashboardGroupsData';
import { Link } from 'react-router-dom';
import DashboardMethodsData from './DashboardMethodsData';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdmin } from 'redux/actions/setAdminActions';
import { fetchDashboardExcelReport } from 'api/fetchGeneralStatistic';
import { useState } from 'react';
import { Loader } from 'components/Loader';

export const DashboardStatisticContent = () => {
    const dispatch = useDispatch();
    const { admin } = useSelector((state) => state.admin);
    const dashboard = useSelector((state) => state.dashboard);
    const [isReportLoading, setIsReportLoading] = useState(false);

    const logoutHandler = () => {
        dispatch(logoutAdmin());
    };

    const regionReportHandler = async () => {
        if (!dashboard.currentRegion || dashboard.currentRegion === '') return;
        setIsReportLoading(true);
        const response = await fetchDashboardExcelReport(dashboard.currentRegion);

        if (response) {
            setIsReportLoading(false);
        }
    };

    return (
        <div className={styles.dashboardStatisticContent}>
            {admin?.type !== 'onlyMunicipality' && admin?.type !== 'onlyDash' && (
                <Link to={'/'} className={styles.backLink}>
                    <img src={ArrowLeftIcon} alt="Назад" />
                    <p>Назад</p>
                </Link>
            )}
            <div className={styles.row}>
                <h2 className={styles.heading}>Статистика по регионам</h2>
                <div className={styles.buttonsRow}>
                    {dashboard?.currentRegion && (
                        <button
                            onClick={isReportLoading ? () => {} : regionReportHandler}
                            className={styles.getExcelReport}
                        >
                            {isReportLoading ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                >
                                    <Loader />
                                </div>
                            ) : (
                                'Отчет по региону'
                            )}
                        </button>
                    )}

                    {(admin?.type === 'onlyMunicipality' || admin?.type === 'onlyDash') && (
                        <button onClick={logoutHandler} className={styles.logoutButton}>
                            Выйти из аккаунта
                        </button>
                    )}
                </div>
            </div>
            <DashboardMainData />
            <DashboardGroupsData />
            <DashboardMethodsData />
            <div className={styles.showStatsByMethodBlock}>
                <Link to={'/statisticByMethods'} className={styles.showStatsByMethod}>
                    Показать статистику по методикам
                </Link>
            </div>
        </div>
    );
};
